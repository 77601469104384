/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    span: "span",
    h3: "h3",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Real-time communication is essential for modern businesses, whether for customer support, internal collaboration, or user engagement. Developing a scalable and high-performance chat application requires the right ", React.createElement(_components.a, {
    href: "/technology-stack.html"
  }, "technology stack"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/nodejs-development.html"
  }, "Node.js development services"), " combined with Socket.io have emerged as the preferred choices for building real-time applications due to their speed, efficiency, and seamless bidirectional communication."), "\n", React.createElement(_components.p, null, "Unlike traditional web applications stacks like LAMP (PHP) that rely on constant server polling, Socket.io ensures instant data transmission with minimal latency. This makes it ideal for businesses looking to enhance user interactions and optimize server performance."), "\n", React.createElement(_components.p, null, "In this guide, we will walk through the development process of building a chat application using Node.js and Socket.io, highlighting the key technologies and best practices involved."), "\n", React.createElement(_components.h2, {
    id: "technological-stack",
    style: {
      position: "relative"
    }
  }, "Technological stack", React.createElement(_components.a, {
    href: "#technological-stack",
    "aria-label": "technological stack permalink",
    className: "target-heading after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill=\"#42b847\" fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, null, "1. WebSockets and socket.io"), "\n", React.createElement(_components.p, null, "Websocket is a protocol which provides a synchronized mutual exchange between the server and the client."), "\n", React.createElement(_components.p, null, "In the classic case, client sends a request to the server and server responds by sending back the data."), "\n", React.createElement(_components.p, null, "In WebSockets, both the server and client can send data. It is a kind of two-way communication process."), "\n", React.createElement(_components.p, null, "Socket.io is nothing but a library based on this protocol which enables easier use of WebSockets."), "\n", React.createElement(_components.h3, null, "3. The Javascript mafia"), "\n", React.createElement(_components.p, null, "Node.js is a Javascript back-end technology that produces asynchronous codes wehich ar executed by the servers as PHP, Ruby or Python. It uses events to achieve this."), "\n", React.createElement(_components.p, null, "Node.js has its own package manager called npm which is easy to install, update, and delete."), "\n", React.createElement(_components.a, {
    href: "/request-quote.html"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 848px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 29.71698113207547%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnUlEQVR42h2R2VLaAABF8xWtTtUCqeyLxSIEDJTQECBgIARQNtlRaLUylS52xi52Wn+jffEvT9M+nKc7c+/MucLsNs/r7wazz0V6K4XmPE25nUA5iiIpIfYlP6GoG39YxBNw4g48xRPawbu3jW9/i8DBFiHpH0/Yff4I4c23KsvbOmefqqzvRiw+tJhcWnSmFRr9EhUrR9HIouoyavnwPzk9QcFMUmom0Y8TJIouMlUPcc2BML8pcvm1xcf7Kdd24cVNl/mqRf+shtkq0ek3OOnadBoYZomTvsXpzKI3rzFY1FiuO3QXBWTjmY2IMHyXZ/a+wnBVpH2eoz5M28sSWX0fc5pieG2reJtnYOe9K5WjQZxDNYJcDCHrPpR6EMXykTFF1I4PoXuRY7LWaS9zNKYZjF4KrR5D1iKc/1T59TDm7s+IH79H3D9MGH95SSzjJaaIxPMOpLILuSaSNp2Ux2GEV1YE41RCa0ZRqmGSmpeoLBJ8YcsPb+PybeJwP8bp3WA3tInfPiKc2iGhiWRNL4VukMo4QnW+x/HVAX8BFHXg1+rc8SsAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"using-node-js-cta-01\"\n        title=\"\"\n        data-src=\"/static/cfff792ade9edd586c370d30273daedf/c5b3e/using-node-js-cta-01.png\"\n        data-srcset=\"/static/cfff792ade9edd586c370d30273daedf/67ada/using-node-js-cta-01.png 212w,\n/static/cfff792ade9edd586c370d30273daedf/f0278/using-node-js-cta-01.png 424w,\n/static/cfff792ade9edd586c370d30273daedf/c5b3e/using-node-js-cta-01.png 848w\"\n        sizes=\"(max-width: 848px) 100vw, 848px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.h2, {
    id: "web-structure",
    style: {
      position: "relative"
    }
  }, "Web structure", React.createElement(_components.a, {
    href: "#web-structure",
    "aria-label": "web structure permalink",
    className: "target-heading after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill=\"#42b847\" fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The primary objective is to setup a straightforward HTML website page that serves out a structure and a rundown of messages. We are going to utilize the Node.js web structure ensuring that it is installed."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 14.622641509433961%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aAD/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFRABAQAAAAAAAAAAAAAAAAAAARD/2gAIAQEAAT8hC//aAAwDAQACAAMAAAAQ88//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAWEAEBAQAAAAAAAAAAAAAAAAABENH/2gAIAQEAAT8QBXb/AP/Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-01\"\n        title=\"\"\n        data-src=\"/static/57342f136532afdb5c68dc0ad6c66024/189bc/create-chat-code-01.jpg\"\n        data-srcset=\"/static/57342f136532afdb5c68dc0ad6c66024/a33d6/create-chat-code-01.jpg 212w,\n/static/57342f136532afdb5c68dc0ad6c66024/56e92/create-chat-code-01.jpg 424w,\n/static/57342f136532afdb5c68dc0ad6c66024/189bc/create-chat-code-01.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "Let us have the dependencies to be populated easily with the help of things that we require such as an npm install –save:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "npm install –save express @4.10.2")), "\n", React.createElement(_components.p, null, "Since we are done with installing express, now we can have our application setup by creating an index.js file."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 22.641509433962263%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aAD/8QAFRABAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQEAAQUCj//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEAAT8hf//aAAwDAQACAAMAAAAQc8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAXEAEAAwAAAAAAAAAAAAAAAAABABAh/9oACAEBAAE/EAQwr//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-02\"\n        title=\"\"\n        data-src=\"/static/f461b3026f45e2bb3fc1514b178883b6/189bc/create-chat-code-02.jpg\"\n        data-srcset=\"/static/f461b3026f45e2bb3fc1514b178883b6/a33d6/create-chat-code-02.jpg 212w,\n/static/f461b3026f45e2bb3fc1514b178883b6/56e92/create-chat-code-02.jpg 424w,\n/static/f461b3026f45e2bb3fc1514b178883b6/189bc/create-chat-code-02.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.h3, null, "What we did here is give below:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "HTTP server supplied with a function handler initialized by Express."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "As soon as website home hit, a route handler is called that is predefined."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Port 3000 occupied with http server listen created by us.\r\nFollowing screen is displayed when node index.js executed:"), "\n"), "\n"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.50943396226415%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAQFBv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAYdeGrB//8QAGBAAAwEBAAAAAAAAAAAAAAAAAQIEABP/2gAIAQEAAQUCtdhV0fQkmX//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAAAgERMpH/2gAIAQEABj8CemmDbdFs/8QAGxABAAICAwAAAAAAAAAAAAAAAQAhETFhcfH/2gAIAQEAAT8hPAq08T0EZUrlt7n/2gAMAwEAAgADAAAAEHPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhABAAMAAwAAAAAAAAAAAAAAAQARITHB8P/aAAgBAQABPxAC5yKHCey7iF3alrqf/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-03\"\n        title=\"\"\n        data-src=\"/static/19287b921b9202d340ec3bc1413d5d34/189bc/create-chat-code-03.jpg\"\n        data-srcset=\"/static/19287b921b9202d340ec3bc1413d5d34/a33d6/create-chat-code-03.jpg 212w,\n/static/19287b921b9202d340ec3bc1413d5d34/56e92/create-chat-code-03.jpg 424w,\n/static/19287b921b9202d340ec3bc1413d5d34/189bc/create-chat-code-03.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "When https: //localhost: 3000: visited in your browser, you should see this:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 54.71698113207547%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe5dUgP/xAAYEAACAwAAAAAAAAAAAAAAAAABERIgIf/aAAgBAQABBQKOoV//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAYEAEBAQEBAAAAAAAAAAAAAAABEQAgYf/aAAgBAQABPyFLVd5YAIcf/9oADAMBAAIAAwAAABBgz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABgQAQEBAQEAAAAAAAAAAAAAAAERACAx/9oACAEBAAE/EEzovmDIbNEOP//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-04\"\n        title=\"\"\n        data-src=\"/static/18a6c6e1f17696b8f445e5dace3efbf2/189bc/create-chat-code-04.jpg\"\n        data-srcset=\"/static/18a6c6e1f17696b8f445e5dace3efbf2/a33d6/create-chat-code-04.jpg 212w,\n/static/18a6c6e1f17696b8f445e5dace3efbf2/56e92/create-chat-code-04.jpg 424w,\n/static/18a6c6e1f17696b8f445e5dace3efbf2/189bc/create-chat-code-04.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "html-coding",
    style: {
      position: "relative"
    }
  }, "HTML coding", React.createElement(_components.a, {
    href: "#html-coding",
    "aria-label": "html coding permalink",
    className: "target-heading after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill=\"#42b847\" fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Until this time, we have passed it into an HTML string after res.send called inside index.js."), "\n", React.createElement(_components.p, null, "It would be very confusing in terms of display, if the entire HTML code placed within. Hence, instead we will serve an index.html file after creating it."), "\n", React.createElement(_components.p, null, "To do this, let us use the sendFile through route handler refactoring:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 9.433962264150944%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAACABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2qAH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFxAAAwEAAAAAAAAAAAAAAAAAARARUf/aAAgBAQABPyGDH//aAAwDAQACAAMAAAAQA8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAABBQAAAAAAAAAAAAAAAAABABAxUZH/2gAIAQEAAT8QFDEIb//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-05\"\n        title=\"\"\n        data-src=\"/static/aff95e4fad8ca92a7ca1c53f60cb4bb3/189bc/create-chat-code-05.jpg\"\n        data-srcset=\"/static/aff95e4fad8ca92a7ca1c53f60cb4bb3/a33d6/create-chat-code-05.jpg 212w,\n/static/aff95e4fad8ca92a7ca1c53f60cb4bb3/56e92/create-chat-code-05.jpg 424w,\n/static/aff95e4fad8ca92a7ca1c53f60cb4bb3/189bc/create-chat-code-05.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "And also perform index.html populating in parallel:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 47.64150943396226%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdiyQD//xAAWEAADAAAAAAAAAAAAAAAAAAAAICH/2gAIAQEAAQUCKv8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhAAAgIDAAAAAAAAAAAAAAAAARAAIRExcf/aAAgBAQABPyG81Bwhp//aAAwDAQACAAMAAAAQMM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhQVEQ/9oACAEBAAE/EDMMC8FzowXuoTTco55//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-06\"\n        title=\"\"\n        data-src=\"/static/13ad8c56d8771f1b158d9a46830ba910/189bc/create-chat-code-06.jpg\"\n        data-srcset=\"/static/13ad8c56d8771f1b158d9a46830ba910/a33d6/create-chat-code-06.jpg 212w,\n/static/13ad8c56d8771f1b158d9a46830ba910/56e92/create-chat-code-06.jpg 424w,\n/static/13ad8c56d8771f1b158d9a46830ba910/189bc/create-chat-code-06.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "Refresh the page after the process restart (running node index after hitting Ctrl+C) and you should see something like this:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 55.660377358490564%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB26kA4WN//8QAGRABAQADAQAAAAAAAAAAAAAAAQACAxEx/9oACAEBAAEFAkiJ9tb3H//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABgQAAIDAAAAAAAAAAAAAAAAAAARICEx/9oACAEBAAY/AmZCz//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRMWH/2gAIAQEAAT8h5CeuPhABk0rhjkS5XrP/2gAMAwEAAgADAAAAEDff/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREA/9oACAEDAQE/EJhpd//EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAgEBPxArn//EABsQAQEBAAMBAQAAAAAAAAAAAAERACFRgUGh/9oACAEBAAE/EDqEftt/HIgfRugAh07iAMeKaCQD2GeUJX3f/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-07\"\n        title=\"\"\n        data-src=\"/static/0a9bd729b77d8f76d81d78e92aeafc3d/189bc/create-chat-code-07.jpg\"\n        data-srcset=\"/static/0a9bd729b77d8f76d81d78e92aeafc3d/a33d6/create-chat-code-07.jpg 212w,\n/static/0a9bd729b77d8f76d81d78e92aeafc3d/56e92/create-chat-code-07.jpg 424w,\n/static/0a9bd729b77d8f76d81d78e92aeafc3d/189bc/create-chat-code-07.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "socketio-integration",
    style: {
      position: "relative"
    }
  }, "Socket.IO Integration", React.createElement(_components.a, {
    href: "#socketio-integration",
    "aria-label": "socketio integration permalink",
    className: "target-heading after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill=\"#42b847\" fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Two parts combine together to form Socket.io:")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Node.JS HTTP Server: socket.io integration with a server"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Socket.io-client browser side that loads a client library"), "\n"), "\n"), "\n", React.createElement(_components.p, null, "Now only one module needs to be installed and the client is served automatically by Socket.io, while still in the development phase:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "npm install –save socket.io")), "\n", React.createElement(_components.p, null, "Let us now add after editing index.js. This is done for adding package.json dependency once module is installed."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 31.132075471698116%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdm4SD//xAAVEAEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAQABBQKIj//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABgQAAIDAAAAAAAAAAAAAAAAAAABEBEh/9oACAEBAAE/IVcWIf/aAAwDAQACAAMAAAAQcA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAYEAEAAwEAAAAAAAAAAAAAAAABABEhUf/aAAgBAQABPxBReRQGS3Sf/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-08\"\n        title=\"\"\n        data-src=\"/static/5e4bc217ddaf8e8e91285f9d2068f095/189bc/create-chat-code-08.jpg\"\n        data-srcset=\"/static/5e4bc217ddaf8e8e91285f9d2068f095/a33d6/create-chat-code-08.jpg 212w,\n/static/5e4bc217ddaf8e8e91285f9d2068f095/56e92/create-chat-code-08.jpg 424w,\n/static/5e4bc217ddaf8e8e91285f9d2068f095/189bc/create-chat-code-08.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "Recognize that I instate another example of Socket.io through ", React.createElement(_components.strong, null, "HTTP"), " (the ", React.createElement(_components.strong, null, "HTTP"), " server) object passing."), "\n", React.createElement(_components.p, null, "At that point, I log in the incoming sockets connection event to the console. Presently in index.html, I included the accompanying snippet before the end of body:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 12.264150943396228%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAACABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aAD/8QAFRABAQAAAAAAAAAAAAAAAAAAARD/2gAIAQEAAQUCL//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEAAT8hX//aAAwDAQACAAMAAAAQ88//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAWEAEBAQAAAAAAAAAAAAAAAAABEFH/2gAIAQEAAT8QJl//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-09\"\n        title=\"\"\n        data-src=\"/static/431df8b6c5502a921a277760b4fc2cc8/189bc/create-chat-code-09.jpg\"\n        data-srcset=\"/static/431df8b6c5502a921a277760b4fc2cc8/a33d6/create-chat-code-09.jpg 212w,\n/static/431df8b6c5502a921a277760b4fc2cc8/56e92/create-chat-code-09.jpg 424w,\n/static/431df8b6c5502a921a277760b4fc2cc8/189bc/create-chat-code-09.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "This is all about loading the Socket.io-client, getting connected after uncovering an io worldwide."), "\n", React.createElement(_components.p, null, "Recognize that I’m not determining any ", React.createElement(_components.strong, null, "URL"), " when ", React.createElement(_components.strong, null, "I"), " call io(), since it defaults to attempting to connect with the host serving that particular page."), "\n", React.createElement(_components.p, null, "Now if both website and server is reloaded, you ought to see the message, “a user connected”. See same message few more times when several tabs opened together:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.50943396226415%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAQFBv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAYdeGrB//8QAGRAAAwADAAAAAAAAAAAAAAAAAQIEABET/9oACAEBAAEFArXYVdHyE7l//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECkREy/9oACAEBAAY/ArSb091pPT//xAAbEAEAAgIDAAAAAAAAAAAAAAABACERMWFx8f/aAAgBAQABPyE0IrXCegjJSuW3uf/aAAwDAQACAAMAAAAQ88//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUHB8P/aAAgBAQABPxAIexEAw4nku4nd2pbtP//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-10\"\n        title=\"\"\n        data-src=\"/static/9adc412a1da39643f0db26a3f41acd65/189bc/create-chat-code-10.jpg\"\n        data-srcset=\"/static/9adc412a1da39643f0db26a3f41acd65/a33d6/create-chat-code-10.jpg 212w,\n/static/9adc412a1da39643f0db26a3f41acd65/56e92/create-chat-code-10.jpg 424w,\n/static/9adc412a1da39643f0db26a3f41acd65/189bc/create-chat-code-10.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "A peculiar disconnect event is also fired by every socket:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 16.509433962264154%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aAD/8QAFRABAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQEAAQUCj//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAABD/2gAIAQEABj8Cf//EABYQAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAQABPyEGX//aAAwDAQACAAMAAAAQg8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEAAgMAAAAAAAAAAAAAAAABABARITH/2gAIAQEAAT8QYFNwAMHK/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-11\"\n        title=\"\"\n        data-src=\"/static/792a171f1b5f2311d9dc30b7e8de3af7/189bc/create-chat-code-11.jpg\"\n        data-srcset=\"/static/792a171f1b5f2311d9dc30b7e8de3af7/a33d6/create-chat-code-11.jpg 212w,\n/static/792a171f1b5f2311d9dc30b7e8de3af7/56e92/create-chat-code-11.jpg 424w,\n/static/792a171f1b5f2311d9dc30b7e8de3af7/189bc/create-chat-code-11.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "Now you can see the below change in messages after a tab is refreshed multiple times:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 41.50943396226415%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAQFBv/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAYlcGrB//8QAGRAAAwADAAAAAAAAAAAAAAAAAQIEABET/9oACAEBAAEFArXYU9HyE7l//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGRAAAwEBAQAAAAAAAAAAAAAAAAECkREy/9oACAEBAAY/ArSb091pPT//xAAbEAEAAgIDAAAAAAAAAAAAAAABADERIWFx8f/aAAgBAQABPyEWOq4T0EZKVy7e5//aAAwDAQACAAMAAAAQc8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAgIDAAAAAAAAAAAAAAABABEhMUHB8P/aAAgBAQABPxAgbqIBhxPZdxO7tS3af//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-12\"\n        title=\"\"\n        data-src=\"/static/4b67b2591b1c4eddfc002011922cd5b5/189bc/create-chat-code-12.jpg\"\n        data-srcset=\"/static/4b67b2591b1c4eddfc002011922cd5b5/a33d6/create-chat-code-12.jpg 212w,\n/static/4b67b2591b1c4eddfc002011922cd5b5/56e92/create-chat-code-12.jpg 424w,\n/static/4b67b2591b1c4eddfc002011922cd5b5/189bc/create-chat-code-12.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "emitting",
    style: {
      position: "relative"
    }
  }, "Emitting", React.createElement(_components.a, {
    href: "#emitting",
    "aria-label": "emitting permalink",
    className: "target-heading after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill=\"#42b847\" fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The principle thought behind Socket.io is that you can receive and send as many events as you want, with any information you need. Any JSON encoded objects will work supported by binary data as well."), "\n", React.createElement(_components.p, null, "Let us just do it in a way wherein the server receives a chat message whenever the user types a message. Now the inindex.html section should be highlighted as below:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 25.943396226415093%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAFABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aAD/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAQABPyFKkz//2gAMAwEAAgADAAAAEAPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGhAAAQUBAAAAAAAAAAAAAAAAAQAQESFRkf/aAAgBAQABPxAdlDBxv//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-13\"\n        title=\"\"\n        data-src=\"/static/c40b8d745be7023477606733f31a228c/189bc/create-chat-code-13.jpg\"\n        data-srcset=\"/static/c40b8d745be7023477606733f31a228c/a33d6/create-chat-code-13.jpg 212w,\n/static/c40b8d745be7023477606733f31a228c/56e92/create-chat-code-13.jpg 424w,\n/static/c40b8d745be7023477606733f31a228c/189bc/create-chat-code-13.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "And the chat message event printed in index.js is as below:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 9.90566037735849%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAACABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aAD/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFxABAAMAAAAAAAAAAAAAAAAAAQAQMf/aAAgBAQABPyEMhX//2gAMAwEAAgADAAAAEAPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAGBABAAMBAAAAAAAAAAAAAAAAAQARIRD/2gAIAQEAAT8QCsEAGFc//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-14\"\n        title=\"\"\n        data-src=\"/static/c5bc2b46c8be8bef01b2279414ab48bc/189bc/create-chat-code-14.jpg\"\n        data-srcset=\"/static/c5bc2b46c8be8bef01b2279414ab48bc/a33d6/create-chat-code-14.jpg 212w,\n/static/c5bc2b46c8be8bef01b2279414ab48bc/56e92/create-chat-code-14.jpg 424w,\n/static/c5bc2b46c8be8bef01b2279414ab48bc/189bc/create-chat-code-14.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.h2, {
    id: "broadcasting",
    style: {
      position: "relative"
    }
  }, "Broadcasting", React.createElement(_components.a, {
    href: "#broadcasting",
    "aria-label": "broadcasting permalink",
    className: "target-heading after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill=\"#42b847\" fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The final step is all users should get the message from the server when the event is emitted. An io.emit is given by the socket for targeting everyone with the message."), "\n", React.createElement(_components.p, null, "Make use of the broadcast flag if a certain socket is not to be targeted excluding it from a message being sent to everyone."), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 9.90566037735849%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAACABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2qAH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFhABAQEAAAAAAAAAAAAAAAAAARAR/9oACAEBAAE/IQMv/9oADAMBAAIAAwAAABBzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABkQAQACAwAAAAAAAAAAAAAAAAEAMRARkf/aAAgBAQABPxBgdHJVY//Z'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-15\"\n        title=\"\"\n        data-src=\"/static/e7a1404ff4801aacebfa92fdc5842134/189bc/create-chat-code-15.jpg\"\n        data-srcset=\"/static/e7a1404ff4801aacebfa92fdc5842134/a33d6/create-chat-code-15.jpg 212w,\n/static/e7a1404ff4801aacebfa92fdc5842134/56e92/create-chat-code-15.jpg 424w,\n/static/e7a1404ff4801aacebfa92fdc5842134/189bc/create-chat-code-15.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "Let us send message to everyone including the sender as well for simplicity sake:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 14.150943396226415%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAADABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2qAH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFxABAAMAAAAAAAAAAAAAAAAAARARMf/aAAgBAQABPyECsn//2gAMAwEAAgADAAAAEHPP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAFxABAQEBAAAAAAAAAAAAAAAAAREAEP/aAAgBAQABPxBQUYAIE5//2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-16\"\n        title=\"\"\n        data-src=\"/static/81554c6990c86a0ac7e9742f3a41b2a8/189bc/create-chat-code-16.jpg\"\n        data-srcset=\"/static/81554c6990c86a0ac7e9742f3a41b2a8/a33d6/create-chat-code-16.jpg 212w,\n/static/81554c6990c86a0ac7e9742f3a41b2a8/56e92/create-chat-code-16.jpg 424w,\n/static/81554c6990c86a0ac7e9742f3a41b2a8/189bc/create-chat-code-16.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, "The message is included in the page once captured on the client side. The JavaScript code on the client side totals up by:"), "\n", React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 725px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 28.77358490566038%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAIF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB2aAD/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFRABAQAAAAAAAAAAAAAAAAAAARD/2gAIAQEAAT8hC//aAAwDAQACAAMAAAAQg8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAaEAEAAQUAAAAAAAAAAAAAAAABABARIUGx/9oACAEBAAE/EGcnYX3T/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"create-chat-code-17\"\n        title=\"\"\n        data-src=\"/static/61dce4115dcd29281adba5c36b771e8b/189bc/create-chat-code-17.jpg\"\n        data-srcset=\"/static/61dce4115dcd29281adba5c36b771e8b/a33d6/create-chat-code-17.jpg 212w,\n/static/61dce4115dcd29281adba5c36b771e8b/56e92/create-chat-code-17.jpg 424w,\n/static/61dce4115dcd29281adba5c36b771e8b/189bc/create-chat-code-17.jpg 725w\"\n        sizes=\"(max-width: 725px) 100vw, 725px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  }), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Hurray, we build our first chat application with just 20 lines of code.")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, "Conclusion", React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "target-heading after"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" height=\"20\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"20\"><path fill=\"#42b847\" fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Building a real-time chat application requires the right technology, and Node.js with Socket.io simplifies this process. In this guide, we explored how these technologies work together to enable seamless, low-latency communication. Without them, developing a responsive chat-based app would be far more complex."), "\n", React.createElement(_components.p, null, "For businesses looking to integrate real-time messaging, ", React.createElement(_components.a, {
    href: "/hire-nodejs-developers.html"
  }, "hiring dedicated Node.js developers"), " is essential. A skilled team ensures scalability, security, and optimal performance, helping you build a chat solution tailored to your business needs."), "\n", React.createElement(_components.a, {
    href: "/request-quote.html"
  }, React.createElement(_components.span, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 848px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 29.71698113207547%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnklEQVR42g2Qa1fSAACG9ytKUwoEJ3IZRiBynW1j3Lc1LgKDgNDAIgzLLnY8lkdP/Yg+mf/zaR/e8357zvu8wuxaZ3FjcHypMzpX6cwK1J00ihEnrUSJH+wSjYuEJD9i2Md26Ck7UTd7HkIvPISTG4RTT4imN9h+/ghhcWOy/NXh2583rH4OmV+0GS8shjOLzrhGo61SfaWgVXNULYWyJfOymqTSzFJuHWA4ObRmjLwpkir5EOZXNU5/GCyu2ny9m/Lh0mH+qcvwrY3drVGsFKg1dNpdm07Ppjuw6Y0txqdN5ud9Ru8b5A2RnBGgYAYQJp91Tr7UGS6L9N+p2BNX+ShLqZVkdFFkuNIYnLn9Ucc50+gtZeS6hFyNcWhEkM0gBSuAbPspOrsIg6XKsQt0FirtExnzdRbNTFDpJ7i+P+L234i7hwm39xN+P0z5/tdGbUmkFJF93UumvkXBdtc1fdSnEoLWimGO0pQ6cRRLIlMKEs/7iSS8iNImW8F1vOJjfME1AtF1QgkPscwz9y8/h80g5UGExjSGNduju9rnP9cn4QmZfwITAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image lazyload\"\n        alt=\"using-node-js-cta-02\"\n        title=\"\"\n        data-src=\"/static/2d5c3cb85fbcffe4500aa4fe13d23ce8/c5b3e/using-node-js-cta-02.png\"\n        data-srcset=\"/static/2d5c3cb85fbcffe4500aa4fe13d23ce8/67ada/using-node-js-cta-02.png 212w,\n/static/2d5c3cb85fbcffe4500aa4fe13d23ce8/f0278/using-node-js-cta-02.png 424w,\n/static/2d5c3cb85fbcffe4500aa4fe13d23ce8/c5b3e/using-node-js-cta-02.png 848w\"\n        sizes=\"(max-width: 848px) 100vw, 848px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
